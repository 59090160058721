import './WorkingConditions.scss'
import React from 'react'

const WorkingConditions = () => {
    return (
        <div className='conditions'>
            <div className="container">
                <h2 className='conditions__title'>Working Conditions</h2>
            </div>
        </div>
    )
}

export default WorkingConditions
